import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { ApiLayoutMap } from 'app/services/layout.service';
import { SnackBarService } from 'app/services/snack-bar.service';
import { SelectOption } from 'app/shared/ui-components/form-select/form-select.component';
import {
  ApiTrafficGraph,
  LayoutMarker,
  Mission,
  Robot,
  Skill,
  Vertex,
} from 'rm-api-services/dist/api-services';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { NewJobService } from 'app/services/new-job.service';
import { DatePipe } from '@angular/common';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { UtilityService } from 'app/services/utility.service';
import { get } from 'lodash';
import _ from 'lodash';
import { CurrentMissionService } from 'app/services/current-job.service';
import { ModalReplaceCurrentJobComponent } from '../modal-replace-current-job/modal-replace-current-job.component';
import { MatDialog } from '@angular/material/dialog';
import {
  RemoveBracketPipe,
  RemoveDateTimePipe,
} from 'app/shared/pipes/jobname.pipe';

@Component({
  selector: 'patrol-job-creation',
  templateUrl: './patrol.component.html',
  styleUrls: ['./patrol.component.scss'],
})
export class PatrolComponent implements OnInit, OnDestroy {
  @ViewChild('bottomOfJobCreation', { static: false })
  bottomOfJobCreationRef: ElementRef;

  @Input() layoutId: string;
  @Input() events: Observable<void>;
  @Input() skillId: string;
  @Input() robot: Robot;
  @Input() robots?: Robot[] = [];
  @Input() robotSkills: Skill[];
  @Output() closeDrawer = new EventEmitter();
  @Output() resetType = new EventEmitter();

  selectMarkers: SelectOption[] = [];
  markers: LayoutMarker[] = [];
  routingList: string[] = [];
  isShowAddButton: boolean = true;
  canSelectMarker: boolean = false;
  isDisabledDrag: boolean = true;
  isOnDrag: boolean = false;
  totalRepeat: number = 0;

  schedule: string;
  selectedStartJob: number;

  isBlinkingLightsCheck: boolean;
  blinkingLightsSkill: Skill;
  taskBlinkingLights;
  taskBlinkingLightsOff;
  isBroadcastMessageCheck: boolean;
  broadcastMessageSkill: Skill;
  taskBroadcastMessage;
  taskBroadcastMessageOff;
  ttsMessageSkill: Skill;
  taskTtsMessage;
  lastIndex = 0;

  private currentJob: Mission;
  private currentJobName: string;
  private isConflictJob: boolean = false;
  private robotId: string;
  private subCurrentMissionServices: Subscription;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
    private apiLayoutMap: ApiLayoutMap,
    private dashboardSrvc: DashboardService,
    private snackBar: SnackBarService,
    private newJobSrvc: NewJobService,
    private _datePipe: DatePipe,
    private apiTrafficGraph: ApiTrafficGraph,
    private utilService: UtilityService,
    private currentMissionSrvc: CurrentMissionService,
    public dialog: MatDialog,
    private removeDateTimePipe: RemoveDateTimePipe,
    private removeBracketPipe: RemoveBracketPipe
  ) {
    this.matIconRegistry.addSvgIcon(
      'dashed-line',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '/assets/images/jobs/dashed-border.svg'
      )
    );
  }

  ngOnInit(): void {
    this.selectedStartJob = 5;
    // if selectedLayoutId is changed
    this.apiLayoutMap.selectedLayoutId$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((layoutId) => {
        this.layoutId = layoutId;
        // only show one dropdown at a time, due to this bug
        // on mat-select:
        // https://github.com/angular/components/issues/19510
        this.routingList = [''];
        this.lastIndex = 0;
        this.selectMarkers = [];
        this.isShowAddButton = true;
        this.canSelectMarker = false;
        this.apiLayoutMap.routingList$.next([]);
        this.getTrafficGraph().then((vertices) => {
          vertices
            .filter((vertex) =>
              this.utilService.canShowMarkerIconOnLayout(vertex.name)
            )
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((vertex) => {
              const jsonData = JSON.stringify({
                layoutId: this.layoutId,
                markerId: vertex.id,
                name: vertex.name,
                x: vertex.x,
                y: vertex.y,
                z: 0,
              });
              this.selectMarkers.push({
                display: vertex.name,
                value: jsonData,
              });
            });
        });
      });

    this.events.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      //check if the current job is conflict with the current job
      //if the job is conflict open the dialog to replace the current job
      //else submit the form
      if (this.isConflictJob) {
        this.openDialog();
      } else {
        this.submitForm();
      }
    });

    // check if the robot have below skills
    this.blinkingLightsSkill = this.robotSkills.find(
      (skill) => skill.name === 'RM-BLINKER-PARALLEL'
    );

    this.broadcastMessageSkill = this.robotSkills.find(
      (skill) => skill.name === 'RM-BROADCAST-PARALLEL'
    );

    this.ttsMessageSkill = this.robotSkills.find(
      (skill) => skill.name === 'RM-TTS-PARALLEL'
    );

    //get the selected robotId
    this.dashboardSrvc.selectedRobotId$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((robotId) => {
        this.robotId = robotId;

        //get the current mission base on the current robotId
        //only subscribe to get the current mission when robotId is not null
        if (this.subCurrentMissionServices) {
          this.subCurrentMissionServices.unsubscribe();
        }
        if (this.robotId) {
          this.getCurrentMission();
        }
      });
  }

  selectStartJobType($event): void {
    this.selectedStartJob = $event;
  }
  jobSchedule($event): void {
    this.schedule = $event;
  }

  /**
   * Helper function to get layout marker list form API
   * @returns
   */
  private getTrafficGraph(): Promise<Vertex[]> {
    return new Promise((resolve) => {
      this.apiTrafficGraph.getGraphByLayout(this.layoutId).subscribe((data) => {
        if (data.code === 200) {
          const temp = data?.result?.vertices;
          _.map(temp, (value, key) => {
            if (!value.hasOwnProperty('id')) {
              value['id'] = key;
            }
          });
          const nodeList = Object.keys(temp).map((key) => temp[key]);
          resolve(nodeList);
        }
      });
    });
  }

  /**
   * Helper function to add new route dropdown
   */
  public addRoutingDropdown(): void {
    this.isShowAddButton = false;
    this.isDisabledDrag = true;
    this.canSelectMarker = true;
    this.routingList.push('');
  }

  /**
   * Helper function to remove route dropdown on specific index
   *
   * @param index
   */
  public removeRoutingDropdown(index: number): void {
    this.routingList.splice(index, 1);
    // also, remove empty string from routingList
    // to avoid the bug on mat-select.
    // this.routingList = this.routingList.filter((item) => item !== '');

    this.apiLayoutMap.routingList$.next(this.routingList);
    this.lastIndex--;
    this.updateButtonStatus();
  }

  /**
   * Helper function to assign selected marker from click map or dropdown
   *
   * @param event Value of selected destination
   * @param action type of action which destination is clicked. If 'map' it means new destination clicked anywhere in the map. If 'marker' its mean destination clicked in the marker.
   */
  public handleChangeMarker(
    event: SelectOption,
    action: 'map' | 'marker' | 'option',
    index: number
  ): void {
    const value = event.value;
    const lastIndex = index - 1;

    if (this.routingList[lastIndex] === value && this.lastIndex > 0) {
      return;
    }

    if (action === 'marker') {
      this.lastIndex++;
      if (this.routingList.includes('')) {
        const emptyRouteIndex = this.routingList.findIndex(
          (route) => route === ''
        );
        this.routingList[emptyRouteIndex] = value;
      } else {
        this.routingList.push(value);
      }
    } else if (action === 'map') {
      this.selectMarkers.push({
        display: event.display,
        value,
      });
      this.selectMarkers.sort((a, b) =>
        a.display > b.display ? 1 : b.display > a.display ? -1 : 0
      );
      this.routingList[index] = value;
    } else if (action === 'option') {
      this.lastIndex = this.routingList.length;
      if (this.routingList.includes('')) {
        const emptyRouteIndex = this.routingList.findIndex(
          (route) => route === ''
        );
        this.routingList[emptyRouteIndex] = value;
      } else {
        this.routingList[index] = value;
      }
    }

    this.apiLayoutMap.routingList$.next(this.routingList);

    this.updateButtonStatus();
  }

  /**
   * Helper function to reorder the selected route after drag the list
   *
   * @param event
   */
  public onDropRoute(event: CdkDragDrop<string[]>): void {
    moveItemInArray(this.routingList, event.previousIndex, event.currentIndex);
    this.apiLayoutMap.routingList$.next(this.routingList);
  }

  /**
   * Helper function to update total repeat of the job
   *
   * @param numberRepeat Number of repeat
   */
  public handleRepeatChange(numberRepeat: number): void {
    this.totalRepeat = numberRepeat;
  }

  /**
   * Helper function to update status of map, button, and drag able list
   */
  private updateButtonStatus(): void {
    // If the last dropdown is empty and there is a dropdown, hide add destination button and make the map clickable
    if (this.routingList.includes('') && this.routingList.length > 0) {
      this.isShowAddButton = false;
      this.canSelectMarker = true;
    } else {
      this.isShowAddButton = true;
      this.canSelectMarker = false;
    }

    // If the last dropdown is empty and there is only 1 dropdown, disabled drag function
    if (this.routingList.includes('') || this.routingList.length === 1) {
      this.isDisabledDrag = true;
    } else {
      this.isDisabledDrag = false;
    }
  }

  /**
   * Helper function to create patrol area job
   */
  private submitForm(): void {
    if (this.robots.length === 0 && !this.robot) {
      this.snackBar.openSnackBar({
        message: 'Please select at least 1 robot',
        type: 'failed',
      });
      return;
    }

    // Check if there is no destination selected
    if (this.routingList.length === 0) {
      this.snackBar.openSnackBar({
        message: 'Please select at least one destination',
        type: 'failed',
      });
      return;
    }

    // Check if all destination marker has been selected
    if (this.routingList.includes('')) {
      this.snackBar.openSnackBar({
        message: 'Please fill all the destination dropdown',
        type: 'failed',
      });
      return;
    }

    // Mapping each routing list into task params object format
    const descriptionPatrol: string[] = [];

    //Add all concarent tasks
    let allTask = [];

    // first add the blinking lights on, if toggled
    if (this.isBlinkingLightsCheck) {
      allTask.push(this.taskBlinkingLights);
    }

    // add broadcast on
    if (this.isBroadcastMessageCheck) {
      allTask = [...allTask, ...this.taskBroadcastMessage];
    }
    // add patrol task
    this.routingList.map((route) => {
      const paramObj = JSON.parse(route);
      descriptionPatrol.push(paramObj.name);
      const patrolParams = {
        order: 0,
        skillId: this.skillId,
        params: [
          {
            paramKey: paramObj.name,
            paramValue: route,
          },
        ],
      };
      allTask.push(patrolParams);
    });

    if (this.isBroadcastMessageCheck) {
      // allTask.push(this.taskBroadcastMessage);
      if (this.taskBroadcastMessage[0].params[2].paramKey !== 'tts') {
        this.taskBroadcastMessageOff = {
          order: 0,
          skillId: this.taskBroadcastMessage[0].skillId,
          params: [
            {
              paramKey: 'switch',
              paramValue: 2,
            },
            this.taskBroadcastMessage[0].params[1], // params repeat
            this.taskBroadcastMessage[0].params[2], // params url
            this.taskBroadcastMessage[0].params[3], // params type
          ],
        };
        allTask = [...allTask, this.taskBroadcastMessageOff];
      } else {
        this.taskBroadcastMessageOff = {
          order: 0,
          skillId: this.taskBroadcastMessage[0].skillId,
          params: [
            {
              paramKey: 'switch',
              paramValue: 2,
            },
            this.taskBroadcastMessage[0].params[1], // params repeat
            this.taskBroadcastMessage[0].params[2], // params tts message
          ],
        };
        allTask = [...allTask, this.taskBroadcastMessageOff];
        // allTask.push(this.taskBroadcastMessageOff);
      }
    }

    // last, add the blinking lights off, if toggled
    if (this.isBlinkingLightsCheck) {
      allTask.push(this.taskBlinkingLightsOff);
    }

    const robotIds = this.robot
      ? [this.robot.id]
      : this.robots.map((robot) => robot.id);

    // Create payload to create patrol job based on payload for
    // create mission in https://docs.robotmanager.com/reference/create-a-mission
    const payload = {
      followPath: 1, // 1 = yes; 2 = no;
      mode: this.selectedStartJob,
      name:
        'PATROL AREAS ' +
        this._datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss'),
      priority: 1,
      repeat: this.totalRepeat,
      type: 1,
      tasks: [...allTask],
      layoutId: this.layoutId,
      robotIds: [...robotIds],
      scheduledAt: this.selectedStartJob === 3 ? this.schedule : null,
    };
    payload['description'] = JSON.stringify(descriptionPatrol);
    this.createPatrolJob(payload);
  }

  private createPatrolJob(payload) {
    //assign isSubmited = true to show loading in button
    this.newJobSrvc.isSubmited$.next(true);

    this.newJobSrvc.newJob(payload).subscribe((res) => {
      const robotsLengthStr = this.robots.length > 1 ? 'robots' : 'robot';
      const message = this.robot
        ? this.robot.name
        : `${this.robots.length} ${robotsLengthStr}`;

      if (res.code === 200) {
        this.snackBar.openSnackBar({
          message: `New job added to ${message} successfully.`,
          type: 'success',
        });
        // reset all field
        this.routingList = [];
        this.apiLayoutMap.routingList$.next(this.routingList);
        this.closeDrawer.emit();
        this.resetType.emit();
      } else {
        this.snackBar.openSnackBar({
          message: `Failed add new job to ${message}`,
          type: 'failed',
        });
      }

      //assign isSubmited = false to hide loading in button
      this.newJobSrvc.isSubmited$.next(false);

      //assign isNewJobCreated$ = true, so in the job list there will be a progressbar if the new job created
      this.newJobSrvc.isNewJobCreated$.next(true);
    });
  }

  handleBlinkingLightsCheck(event) {
    this.isBlinkingLightsCheck = event.checked;
    if (this.isBlinkingLightsCheck) {
      //   const paramObj = JSON.parse(this.selectedMarker);
      // switch on the blinking lights
      const taskParam = {
        paramKey: 'switch',
        paramValue: 1,
      };
      this.taskBlinkingLights = {
        order: 0,
        mandatory: 1,
        skillId: this.blinkingLightsSkill.id,
        params: [taskParam],
      };

      // switch off the blinking lights
      const taskParamOff = {
        paramKey: 'switch',
        paramValue: 2,
      };
      this.taskBlinkingLightsOff = {
        order: 0,
        mandatory: 1,
        skillId: this.blinkingLightsSkill.id,
        params: [taskParamOff],
      };
    } else {
      this.taskBlinkingLights = {};
    }

    //check if the current job is conflict with the current job when blinking lights is toggled
    this.isConflictJob = this.newJobSrvc.conflictJob(
      'patrol areas',
      this.currentJob,
      this.selectedStartJob,
      this.isBroadcastMessageCheck
    );
  }

  handleBroadcastMessageCheck(event) {
    this.isBroadcastMessageCheck = event.checked;

    setTimeout(() => {
      this.bottomOfJobCreationRef.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 200);

    //check if the current job is conflict with the current job when broadcast message is toggled
    this.isConflictJob = this.newJobSrvc.conflictJob(
      'patrol areas',
      this.currentJob,
      this.selectedStartJob,
      this.isBroadcastMessageCheck
    );
  }

  taskDataBroadcastMessage(event) {
    this.taskBroadcastMessage = event;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    if (this.subCurrentMissionServices) {
      this.subCurrentMissionServices.unsubscribe();
    }
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // disable the add patrol route button if there is a
  // empty string in the routing list
  public get disableAddPatrolRoute(): boolean {
    return this.routingList.includes('');
  }

  public getRoutingName(routing: string) {
    const json = JSON.parse(routing);
    const found = this.selectMarkers.find((selectMarker) => {
      const marker = JSON.parse(selectMarker.value);
      if (get(marker, 'name') !== get(json, 'name')) return false;
      if (get(marker, 'x') !== get(json, 'x')) return false;
      if (get(marker, 'y') !== get(json, 'y')) return false;

      return true;
    });
    return found ? found.display : '';
  }

  //get the current job and check if the current job is conflict with the new job
  private getCurrentMission() {
    this.subCurrentMissionServices = this.currentMissionSrvc
      .getSubject(this.robot)
      // .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((missions) => {
        // check if there is a mission list
        if (missions.length > 0) {
          this.currentJob = missions?.find((mission) => mission.status === 5);

          // check if there is a current job that running
          if (this.currentJob) {
            // remove date time
            let formattedName = this.removeDateTimePipe.transform(
              this.currentJob.name
            );
            // remove brackets and text within it
            formattedName = this.removeBracketPipe.transform(formattedName);
            // transform to capitalize
            this.currentJobName =
              formattedName.charAt(0).toUpperCase() +
              formattedName.toLocaleLowerCase().slice(1);

            //check if the current job is conflict with the current job
            this.isConflictJob = this.newJobSrvc.conflictJob(
              'patrol areas',
              this.currentJob,
              this.selectedStartJob,
              this.isBroadcastMessageCheck
            );
          }
        }
      });
  }

  private openDialog(): void {
    const dialogRef = this.dialog.open(ModalReplaceCurrentJobComponent, {
      width: '648px',
      panelClass: 'rm-dialog',
      data: { currentJobName: this.currentJobName, newJobName: 'Patrol areas' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      //if the user chose confirm set the selected start job to 5
      // mode `Start now and pause current job` === 5
      this.selectedStartJob = 5;

      // submit the form
      this.submitForm();
    });
  }
}
