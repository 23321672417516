import { InputFileDropzoneComponent } from './../../../../shared/ui-components/input-file-dropzone/input-file-dropzone.component';
import { DatePipe } from '@angular/common';
import { NewJobService } from './../../../../services/new-job.service';
import { SnackBarService } from './../../../../services/snack-bar.service';
import {
  FormGroup,
  FormControl,
  Validators,
  FormGroupDirective,
} from '@angular/forms';
import {
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { SelectOption } from 'app/shared/ui-components/form-select/form-select.component';
import { Mission, Robot, Skill } from 'rm-api-services/dist/api-services';
import { Subject, Observable, Subscription, takeUntil } from 'rxjs';
import { BroadcastData, BroadcastMessages } from 'app/services/api.types';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { ModalReplaceCurrentJobComponent } from '../modal-replace-current-job/modal-replace-current-job.component';
import { MatDialog } from '@angular/material/dialog';
import { CurrentMissionService } from 'app/services/current-job.service';
import {
  RemoveBracketPipe,
  RemoveDateTimePipe,
} from 'app/shared/pipes/jobname.pipe';
import { AppService } from 'app/app.service';

@Component({
  selector: 'broadcast-job-creation',
  templateUrl: './broadcast.component.html',
  styleUrls: ['./broadcast.component.scss'],
})
export class BroadcastComponent implements OnInit, OnChanges, OnDestroy {
  @Input() robot?: Robot;
  @Input() robots?: Robot[] = [];
  @Input() events: Observable<void>;
  @Input() skill: Skill;
  @Input() robotSkills: Skill[];
  @Input() isConcurrentSkill: boolean = false;
  @Input() isBlinker: boolean = false;
  @Input() isChecked: boolean = true;
  @Output() closeDrawer = new EventEmitter();
  @Output() resetType = new EventEmitter();
  @Output() tasksData = new EventEmitter();
  @ViewChild(InputFileDropzoneComponent)
  inputFileDropzone: InputFileDropzoneComponent;

  typeOfMesages: SelectOption[] = [];
  selectedTypeOfMessage: string = null;
  listOfMessages: BroadcastData[] = [];
  form: FormGroup;
  file: File;
  ttsMessageSkill: Skill;
  isBlinkingLightsCheck: boolean;
  taskBlinkingLights;
  taskBlinkingLightsOff;
  blinkingLightsSkill: Skill;
  broadcastParallelSkill: Skill;
  ttsParallelSkill: Skill;

  schedule: string;
  selectedStartJob: number;

  private currentJob: Mission;
  private currentJobName: string;
  private isConflictJob: boolean = false;
  private subCurrentMissionServices: Subscription;
  private robotId: string;

  private eventsSubscription: Subscription;
  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private snackBar: SnackBarService,
    private newJobSrvc: NewJobService,
    private dashboardSrvc: DashboardService,
    private _datePipe: DatePipe,
    private currentMissionSrvc: CurrentMissionService,
    public dialog: MatDialog,
    private removeDateTimePipe: RemoveDateTimePipe,
    private removeBracketPipe: RemoveBracketPipe,
    private appService: AppService
  ) {}

  ngOnInit(): void {
    this.selectedStartJob = 5;
    this.getBroadcastData();
    //check if the current broadcast is concurent skill or not
    //if false the listen to the create job in Broadcast page create job
    if (!this.isConcurrentSkill) {
      this.eventsSubscription = this.events.subscribe(() => {
        if (this.form) {
          //check if the current job is conflict with the current job
          //if the job is conflict open the dialog to replace the current job
          //else submit the form
          if (this.isConflictJob) {
            this.openDialog();
          } else {
            this.submitForm();
          }
        }
      });
    }

    if (this.robotSkills) {
      this.ttsMessageSkill = this.robotSkills.find(
        (skill) => skill.name === 'RM-TTS'
      );

      this.ttsParallelSkill = this.robotSkills.find(
        (skill) => skill.name === 'RM-TTS-PARALLEL'
      );

      this.blinkingLightsSkill = this.robotSkills.find(
        (skill) => skill.name === 'RM-BLINKER-PARALLEL'
      );

      this.broadcastParallelSkill = this.robotSkills.find(
        (skill) => skill.name === 'RM-BROADCAST-PARALLEL'
      );
    }

    //get the selected robotId
    this.dashboardSrvc.selectedRobotId$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((robotId) => {
        this.robotId = robotId;

        //get the current mission base on the current robotId
        //only subscribe to get the current mission when robotId is not null
        if (this.subCurrentMissionServices) {
          this.subCurrentMissionServices.unsubscribe();
        }
        if (this.robotId) {
          this.getCurrentMission();
        }
      });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.robotSkills) {
      if (this.robotSkills) {
        this.ttsMessageSkill = this.robotSkills.find(
          (skill) => skill.name === 'RM-TTS'
        );

        this.ttsParallelSkill = this.robotSkills.find(
          (skill) => skill.name === 'RM-TTS-PARALLEL'
        );

        this.blinkingLightsSkill = this.robotSkills.find(
          (skill) => skill.name === 'RM-BLINKER-PARALLEL'
        );

        this.broadcastParallelSkill = this.robotSkills.find(
          (skill) => skill.name === 'RM-BROADCAST-PARALLEL'
        );
      }
    }
  }

  initForm(type?: string): void {
    if (!this.form) {
      this.form = new FormGroup({
        name: new FormControl(),
        mode: new FormControl(2),
        type: new FormControl(1),
        priority: new FormControl(1),
        tasks: new FormControl([], Validators.required),
        robotIds: new FormControl([], Validators.required),
        repeat: new FormControl(0),
        followPath: new FormControl(2),
        scheduledAt: new FormControl(),
      });
    }

    if (type === 'upload') {
      this.form.patchValue({
        name: `BROADCAST ${this._datePipe.transform(
          new Date(),
          'dd/MM/yyyy HH:mm:ss'
        )}`,
      });

      this.form.removeControl('message');
      this.form.addControl(
        'selectedMedia',
        new FormControl(null, Validators.required)
      );
    } else {
      this.form.patchValue({
        name: `BROADCAST ${this._datePipe.transform(
          new Date(),
          'dd/MM/yyyy HH:mm:ss'
        )}`,
      });

      this.form.removeControl('selectedMedia');
      this.form.addControl('message', new FormControl());
      this.form.addControl('broadcastData', new FormControl());
    }
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    if (this.subCurrentMissionServices) {
      this.subCurrentMissionServices.unsubscribe();
    }
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
    this.form?.reset();
    this.form = undefined;
  }

  selectStartJobType($event): void {
    this.selectedStartJob = $event;

    //check if the current job is conflict with the current job when the user change the start job type
    this.isConflictJob = this.newJobSrvc.conflictJob(
      'broadcasting',
      this.currentJob,
      this.selectedStartJob,
      true
    );
  }

  jobSchedule($event): void {
    this.schedule = $event;
  }

  private getBroadcastData(): void {
    // check if the job is concurrent job or not
    // because the skill for concurrent job is different from a normal job
    // if the job is concurrent job then the skill is RM-{SKILL_NAME}-PARALLEL
    // else the skill is RM-{SKILL_NAME}
    if (this.isConcurrentSkill) {
      //check for RM-TTS-PARALLEL
      if (this.ttsParallelSkill) {
        // Add custom message
        this.typeOfMesages.push({
          display: 'Write a custom message',
          value: 'custom',
          divider: true,
          icon: 'mat_outline:edit',
        });
      }
      //check for RM-BROADCAST-PARALLEL
      if (this.broadcastParallelSkill) {
        // broadcast parallel skill is for predefine message and upload an audio
        this.addPredefineMessage();
        // Add upload an audio
        this.typeOfMesages.push({
          display: 'Upload an audio',
          value: 'upload',
          icon: 'mat_outline:upload_file',
        });
      }
    }
    // check for normal job (not concurrent job)
    else {
      // if (this.ttsMessageSkill) {
        // Add custom message
        this.typeOfMesages.push({
          display: 'Write a custom message',
          value: 'custom',
          divider: true,
          icon: 'mat_outline:edit',
        });
      // }
      if (this.skill?.name === 'RM-BROADCAST') {
        this.addPredefineMessage();
        // Add upload an audio
        this.typeOfMesages.push({
          display: 'Upload an audio',
          value: 'upload',
          icon: 'mat_outline:upload_file',
        });
      }
    }
  }

  private addPredefineMessage(): void {
    this.appService
      .getBroadcastMessageSubject()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        const parseJson = JSON.parse(data) as BroadcastMessages[];
        parseJson.map((bc) => {
          this.typeOfMesages.unshift({
            display: bc.type,
            value: bc.type,
          });
        });
      });
  }

  selectTypeOfMessage($event: SelectOption) {
    this.selectedTypeOfMessage = $event.value;
    this.listOfMessages = [];
    this.initForm($event.value);
    this.appService
      .getBroadcastMessageSubject()
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((data) => {
        const parseJson = JSON.parse(data) as BroadcastMessages[];
        parseJson.map((bc) => {
          if (bc.type === $event.value) {
            bc.messages.map((data) => {
              this.listOfMessages.push(data);
            });
          }
        });
      });
  }

  selectMessage(data: BroadcastData): void {
    this.form.patchValue({
      broadcastData: data,
    });

    //this will run only if the broadcast job is concurrent job
    if (this.isConcurrentSkill) {
      if (this.form.value.broadcastData) {
        this.updateTaskBroadcast();
      }
      this.tasksData.emit(this.form.get('tasks').value);
    }
  }

  onTextMessage(data: string): void {
    this.form.patchValue({
      message: data,
    });

    //this will run only if the broadcast job is concurrent job
    if (this.isConcurrentSkill) {
      if (this.form.value.message) {
        this.updateTaskBroadcast();
      }
      this.tasksData.emit(this.form.get('tasks').value);
    }
  }

  submitForm() {
    let allTask = [];
    if (this.robots.length === 0 && !this.robot) {
      this.snackBar.openSnackBar({
        message: 'Please select at least 1 robot',
        type: 'failed',
      });
      return;
    }

    const robotIds = this.robot
      ? [this.robot.id]
      : this.robots.map((robot) => robot.id);

    if (this.selectedTypeOfMessage === 'upload') {
      if (this.form.value.selectedMedia) {
        if (this.isBlinkingLightsCheck) {
          allTask.push(this.taskBlinkingLights);
        }
        // param task broadcast audio on
        const taskAudio = {
          order: 0,
          skillId: this.isConcurrentSkill
            ? this.broadcastParallelSkill.id
            : this.skill.id,
          params: [
            {
              paramKey: 'switch',
              paramValue: 1,
            },
            {
              paramKey: 'repeat',
              paramValue: this.form.value.repeat,
            },
            {
              paramKey: 'url',
              paramValue: this.form.value.selectedMedia.id,
            },
            {
              paramKey: 'type',
              paramValue: 2,
            },
          ],
        };
        allTask.push(taskAudio);

        if (this.isBlinkingLightsCheck) {
          allTask.push(this.taskBlinkingLightsOff);
        }

        this.form.patchValue({
          tasks: [...allTask],
          robotIds: [...robotIds],
          mode: this.selectedStartJob,
        });
        if (this.selectedStartJob === 3) {
          this.form.patchValue({
            scheduledAt: this.schedule,
          });
        }
      } else {
        this.snackBar.openSnackBar({
          message: 'Please select a media',
          type: 'failed',
        });
        return;
      }
    } else if (this.selectedTypeOfMessage === 'custom') {
      if (this.form.value.message) {
        if (this.isBlinkingLightsCheck) {
          allTask.push(this.taskBlinkingLights);
        }
        // param task broadcast tts
        const taskMessage = {
          order: 0,
          skillId: this.isConcurrentSkill
            ? this.ttsParallelSkill.id
            : this.ttsMessageSkill.id,
          params: [
            {
              paramKey: 'switch',
              paramValue: 1,
            },
            {
              paramKey: 'repeat',
              paramValue: this.form.value.repeat,
            },
            {
              paramKey: 'tts',
              paramValue: this.form.value.message,
            },
          ],
        };
        allTask.push(taskMessage);

        if (this.isBlinkingLightsCheck) {
          allTask.push(this.taskBlinkingLightsOff);
        }

        this.form.patchValue({
          tasks: [...allTask],
          robotIds: [...robotIds],
          mode: this.selectedStartJob,
        });
        if (this.selectedStartJob === 3) {
          this.form.patchValue({
            scheduledAt: this.schedule,
          });
        }
      } else {
        this.snackBar.openSnackBar({
          message: 'Please write a message',
          type: 'failed',
        });
        return;
      }
    } else {
      if (this.form.value.broadcastData) {
        if (this.isBlinkingLightsCheck) {
          allTask.push(this.taskBlinkingLights);
        }
        // param task broadcast audio on
        const taskAudio = {
          order: 0,
          skillId: this.isConcurrentSkill
            ? this.broadcastParallelSkill.id
            : this.skill.id,
          params: [
            {
              paramKey: 'switch',
              paramValue: 1,
            },
            {
              paramKey: 'repeat',
              paramValue: this.form.value.repeat,
            },
            {
              paramKey: 'url',
              paramValue: this.form.value.broadcastData.id,
            },
            {
              paramKey: 'type',
              paramValue: 2,
            },
          ],
        };
        allTask.push(taskAudio);

        if (this.isBlinkingLightsCheck) {
          allTask.push(this.taskBlinkingLightsOff);
        }

        this.form.patchValue({
          tasks: [...allTask],
          robotIds: [...robotIds],
          mode: this.selectedStartJob,
        });
        if (this.selectedStartJob === 3) {
          this.form.patchValue({
            scheduledAt: this.schedule,
          });
        }
      } else {
        this.snackBar.openSnackBar({
          message: 'Please select a message',
          type: 'failed',
        });
        return;
      }
    }

    if (this.form.valid) {
      const data = {
        name: this.form.value.name,
        mode: this.form.value.mode,
        type: this.form.value.type,
        priority: this.form.value.priority,
        tasks: this.form.value.tasks,
        robotIds: this.form.value.robotIds,
        followPath: this.form.value.followPath,
        scheduledAt: this.form.value.scheduledAt,
      };

      if (this.selectedTypeOfMessage === 'custom') {
        data['repeat'] = this.form.value.repeat;
        data['description'] = this.form.value.message;
      } else if (this.selectedTypeOfMessage === 'upload') {
        data['description'] =
          'Broadcasting: ' + this.form.value.selectedMedia.name;
      } else {
        data['description'] = this.form.value.broadcastData.message;
      }

      const robotsLength = this.robots.length;
      const robotsLengthStr = robotsLength > 1 ? 'robots' : 'robot';
      const successMessage =
        robotsLength > 0
          ? `New job added to ${robotsLength} ${robotsLengthStr} successfully`
          : 'Broadcast message sent successfully';

      // check if job mode is `Start now and pause current job`
      // if true, pause all current running job
      // then, create a new broadcast job
      // mode `Start now and pause current job` === 5
      if (this.selectedStartJob === 5) {
        const pausedAllRobotsJob: boolean[] = [];
        robotIds.map(async (robotId) => {
          const isPaused = await this.dashboardSrvc.pauseAllCurrentJob(robotId);
          pausedAllRobotsJob.push(isPaused);
        });

        if (pausedAllRobotsJob.every((result) => result)) {
          // All mission paused successfully, create a new broadcast job
          // based on Kae Yan, the value of the Mission should be like this:
          // mode: 1 = Start now and pause current job
          // scheduleType/status: 4 = start
          data.mode = 1;
          data['status'] = 4;
          this.createBroadcastJob(data, successMessage);
        } else {
          // there are some mission that failed to pause / no mission to pause
          console.log(
            'allPaused',
            pausedAllRobotsJob.every((result) => result)
          );
        }
      } else {
        this.createBroadcastJob(data, successMessage);
      }
    } else {
      this.snackBar.openSnackBar({
        message: 'Please fill all the required fields',
        type: 'failed',
      });
    }
  }

  private createBroadcastJob(payload, message) {
    //assign isSubmited = true to show loading in button
    this.newJobSrvc.isSubmited$.next(true);

    this.newJobSrvc.newJob(payload).subscribe((res) => {
      if (res.code === 200) {
        this.snackBar.openSnackBar({
          message: message,
          type: 'success',
        });
        if (this.selectedTypeOfMessage === 'upload') {
          this.onRemoveFile();
          this.inputFileDropzone.reset();
        }
        this.form = undefined;
        this.closeDrawer.emit();
        this.resetType.emit();
      } else {
        this.snackBar.openSnackBar({
          message: res.message,
          type: 'failed',
        });
      }

      //assign isSubmited = false to hide loading in button
      this.newJobSrvc.isSubmited$.next(false);

      //assign isNewJobCreated$ = true, so in the job list there will be a progressbar if the new job created
      this.newJobSrvc.isNewJobCreated$.next(true);
    });
  }

  selectMediaEvent(media: File): void {
    this.form.patchValue({
      selectedMedia: media,
    });

    //this will run only if the broadcast job is concurrent job
    if (this.isConcurrentSkill) {
      if (this.form.value.selectedMedia) {
        this.updateTaskBroadcast();
      }
      this.tasksData.emit(this.form.get('tasks').value);
    }
    // this._newJobSrvc.selectedMedia$.next(this.selectedMedia);
  }

  onUploadChange(event: any, type: string): void {
    this.file = event[0];
    this.selectMediaEvent(event[0]);
  }

  onRemoveFile(): void {
    this.file = null;
    this.form.patchValue({
      selectedMedia: null,
    });
  }

  onRepeatChange(repeat: number): void {
    this.form.patchValue({
      repeat: repeat,
    });
    //this will run only if the broadcast job is concurrent job
    if (this.isConcurrentSkill) {
      if (this.form.value.repeat) {
        this.updateTaskBroadcast();
      }
      this.tasksData.emit(this.form.get('tasks').value);
    }
  }

  handleBlinkingLightsCheck(event) {
    this.isBlinkingLightsCheck = event.checked;
    if (this.isBlinkingLightsCheck) {
      // switch on the blinking lights
      const taskParam = {
        paramKey: 'switch',
        paramValue: 1,
      };
      this.taskBlinkingLights = {
        order: 0,
        skillId: this.blinkingLightsSkill.id,
        params: [taskParam],
        mandatory: 1,
      };

      // switch off the blinking lights
      const taskParamOff = {
        paramKey: 'switch',
        paramValue: 2,
      };
      this.taskBlinkingLightsOff = {
        order: 0,
        skillId: this.blinkingLightsSkill.id,
        params: [taskParamOff],
        mandatory: 1,
      };
    } else {
      this.taskBlinkingLights = {};
    }
  }

  //funtion to help update task that will be used in concurrent job
  updateTaskBroadcast() {
    const robotIds = this.robot
      ? [this.robot.id]
      : this.robots.map((robot) => robot.id);

    if (this.selectedTypeOfMessage === 'upload') {
      this.form.patchValue({
        tasks: [
          {
            order: 0,
            skillId: this.isConcurrentSkill
              ? this.broadcastParallelSkill.id
              : this.skill.id,
            params: [
              {
                paramKey: 'switch',
                paramValue: 1,
              },
              {
                paramKey: 'repeat',
                paramValue: this.form.value.repeat,
              },
              {
                paramKey: 'url',
                paramValue: this.form.value.selectedMedia.id,
              },
              {
                paramKey: 'type',
                paramValue: 2,
              },
            ],
          },
        ],
        robotIds: [...robotIds],
        mode: this.selectedStartJob,
      });
      if (this.selectedStartJob === 3) {
        this.form.patchValue({
          scheduledAt: this.schedule,
        });
      }
    } else if (this.selectedTypeOfMessage === 'custom') {
      this.form.patchValue({
        tasks: [
          {
            order: 0,
            skillId: this.isConcurrentSkill
              ? this.ttsParallelSkill.id
              : this.ttsMessageSkill.id,
            params: [
              {
                paramKey: 'switch',
                paramValue: 1,
              },
              {
                paramKey: 'repeat',
                paramValue: this.form.value.repeat,
              },
              {
                paramKey: 'tts',
                paramValue: this.form.value.message,
              },
            ],
          },
        ],
        robotIds: [...robotIds],
        mode: this.selectedStartJob,
      });
      if (this.selectedStartJob === 3) {
        this.form.patchValue({
          scheduledAt: this.schedule,
        });
      }
    } else {
      this.form.patchValue({
        tasks: [
          {
            order: 0,
            skillId: this.isConcurrentSkill
              ? this.broadcastParallelSkill.id
              : this.skill.id,
            params: [
              {
                paramKey: 'switch',
                paramValue: 1,
              },
              {
                paramKey: 'repeat',
                paramValue: this.form.value.repeat,
              },
              {
                paramKey: 'url',
                paramValue: this.form.value.broadcastData.id,
              },
              {
                paramKey: 'type',
                paramValue: 2,
              },
            ],
          },
        ],
        robotIds: [...robotIds],
        mode: this.selectedStartJob,
      });
      if (this.selectedStartJob === 3) {
        this.form.patchValue({
          scheduledAt: this.schedule,
        });
      }
    }
  }

  openDialog(): void {
    const dialogRef = this.dialog.open(ModalReplaceCurrentJobComponent, {
      width: '648px',
      panelClass: 'rm-dialog',
      data: { currentJobName: this.currentJobName, newJobName: 'Broadcasting' },
    });

    dialogRef.afterClosed().subscribe((result) => {
      if (!result) {
        return;
      }

      //if the user chose confirm set the selected start job to 5
      // mode `Start now and pause current job` === 5
      this.selectedStartJob = 5;

      // submit the form
      this.submitForm();
    });
  }

  //get the current job and check if the current job is conflict with the new job
  private getCurrentMission() {
    this.subCurrentMissionServices = this.currentMissionSrvc
      .getSubject(this.robot)
      // .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((missions) => {
        // check if there is a mission list
        if (missions.length > 0) {
          this.currentJob = missions?.find((mission) => mission.status === 5);

          // check if there is a current job that running
          if (this.currentJob) {
            // remove date time
            let formattedName = this.removeDateTimePipe.transform(
              this.currentJob.name
            );
            // remove brackets and text within it
            formattedName = this.removeBracketPipe.transform(formattedName);
            // transform to capitalize
            this.currentJobName =
              formattedName.charAt(0).toUpperCase() +
              formattedName.toLocaleLowerCase().slice(1);

            //check if the current job is conflict with the current job
            this.isConflictJob = this.newJobSrvc.conflictJob(
              'broadcasting',
              this.currentJob,
              this.selectedStartJob,
              true
            );
          }
        }
      });
  }
}
