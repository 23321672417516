import { Skill } from 'rm-api-services/dist/api-services';
import { DatePipe } from '@angular/common';
import { FormInputSelectComponent } from './../../../../shared/ui-components/form-input-select/form-input-select.component';
import { Robot } from 'api-services';
import { SnackBarService } from './../../../../services/snack-bar.service';
import { Observable, Subscription } from 'rxjs';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  OnDestroy,
  ViewChild,
  Output,
  EventEmitter,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { NewJobService } from 'app/services/new-job.service';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';

@Component({
  selector: 'app-blinking-light',
  templateUrl: './blinking-light.component.html',
  styleUrls: ['./blinking-light.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,

  // encapsulation: ViewEncapsulation.None,
})
export class BlinkingLightComponent implements OnInit, OnChanges, OnDestroy {
  @Input() events: Observable<void>;
  @Input() robot?: Robot;
  @Input() robots?: Robot[] = [];
  @Input() layoutId?: string;
  @Input() robotSkills: Skill[];
  @Output() closeDrawer = new EventEmitter();
  private eventsSubscription: Subscription;
  form: FormGroup;
  blinkingSkills: Skill;

  schedule: string;
  selectedStartJob: number;

  constructor(
    private snackbarService: SnackBarService,
    private newJob: NewJobService,
    private dashboardSrvc: DashboardService,
    private _datePipe: DatePipe
  ) {}

  ngOnInit(): void {
    this.selectedStartJob = 5;
    this.eventsSubscription = this.events.subscribe(() => {
      this.submitForm();
    });
    this.form = new FormGroup({
      repeat: new FormControl(0, [Validators.min(0)]),
    });

    if (this.robotSkills) {
      this.blinkingSkills = this.robotSkills.find(
        (skill) => skill.name === 'RM-BLINKER'
      );
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.robotSkills) {
      if (this.robotSkills) {
        this.blinkingSkills = this.robotSkills.find(
          (skill) => skill.name === 'RM-BLINKER'
        );
      }
    }
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
    this.form.reset();
  }

  selectStartJobType($event): void {
    this.selectedStartJob = $event;
  }
  jobSchedule($event): void {
    this.schedule = $event;
  }

  public submitForm(): void {
    if (this.robots.length === 0 && !this.robot) {
      this.snackbarService.openSnackBar({
        message: 'Please select at least 1 robot',
        type: 'failed',
      });
      return;
    }

    if (this.form.valid) {
      const robotIds = this.robot
        ? [this.robot.id]
        : this.robots.map((robot) => robot.id);
      const payload = {
        followPath: 2, // 1 = yes; 2 = no;
        mode: this.selectedStartJob,
        name:
          'BLINKING LIGHT ON' +
          this._datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss'),
        priority: 1,
        repeat: this.form.value.repeat,
        type: 1,
        tasks: [
          {
            order: 0,
            skillId: this.blinkingSkills.id,
            params: [
              {
                paramKey: 'switch',
                paramValue: 1,
              },
            ],
          },
        ],
        robotIds: [...robotIds],
        scheduledAt: this.selectedStartJob === 3 ? this.schedule : null,
      };

      const robotsLength = this.robots.length;
      const robotsLengthStr = robotsLength > 1 ? 'robots' : 'robot';
      const blinkingMessage =
        this.form.value.repeat > 0
          ? `Blinking light turned on for ${this.form.value.repeat} times`
          : `Blinking light turned on`;
      const successMessage =
        robotsLength > 0
          ? `New job added to ${robotsLength} ${robotsLengthStr} successfully`
          : blinkingMessage;

      // check if job mode is `Start now and pause current job`
      // if true, pause all current running job
      // then, create a new blinking light job
      // mode `Start now and pause current job` === 5
      if (this.selectedStartJob === 5) {
        const pausedAllRobotsJob: boolean[] = [];
        robotIds.map(async (robotId) => {
          const isPaused = await this.dashboardSrvc.pauseAllCurrentJob(robotId);
          pausedAllRobotsJob.push(isPaused);
        });

        if (pausedAllRobotsJob.every((result) => result)) {
          // All mission paused successfully, create a new blinking light job
          // based on Kae Yan, the value of the Mission should be like this:
          // mode: 1 = Start now and pause current job
          // scheduleType/status: 4 = start
          payload.mode = 1;
          payload['status'] = 4;
          this.createBlinkingLightJob(payload, successMessage);
        } else {
          // there are some mission that failed to pause / no mission to pause
          console.log(
            'allPaused',
            pausedAllRobotsJob.every((result) => result)
          );
        }
      } else {
        this.createBlinkingLightJob(payload, successMessage);
      }
    } else {
      this.snackbarService.openSnackBar({
        message: `Form is invalid`,
        type: 'failed',
      });
    }
  }

  private createBlinkingLightJob(payload, message) {
    //assign isSubmited = true to show loading in button
    this.newJob.isSubmited$.next(true);

    this.newJob.newJob(payload).subscribe((res) => {
      if (res.code === 200) {
        this.snackbarService.openSnackBar({
          message: message,
          type: 'success',
        });
        this.closeDrawer.emit();
      } else {
        this.snackbarService.openSnackBar({
          message: `Failed to turn on blinking light`,
          type: 'failed',
        });
      }

      //assign isSubmited = false to hide loading in button
      this.newJob.isSubmited$.next(false);

      //assign isNewJobCreated$ = true, so in the job list there will be a progressbar if the new job created
      this.newJob.isNewJobCreated$.next(true);
    });
  }

  onRepeatChange(repeat: number): void {
    this.form.patchValue({
      repeat: repeat,
    });
  }
}
