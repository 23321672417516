import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ApiLayoutMap } from 'app/services/layout.service';
import { SnackBarService } from 'app/services/snack-bar.service';
import { SelectOption } from 'app/shared/ui-components/form-select/form-select.component';
import { Robot, Skill } from 'rm-api-services/dist/api-services';
import { Observable, Subject, takeUntil } from 'rxjs';
import { NewJobService } from 'app/services/new-job.service';
import { DatePipe } from '@angular/common';
import { DashboardService } from 'app/modules/dashboard/dashboard.service';
import { MatDialog } from '@angular/material/dialog';
import { Region } from 'app/services/api.types';
import { ApiRegion } from 'app/services/region.service';
import { ApiCampaign } from 'app/services/campaign.service';
import { get } from 'lodash';
import { PrettifyPrintPipe } from 'app/shared/pipes/pretify-string.pipe';

@Component({
  selector: 'group-patrol-job-creation',
  templateUrl: './group-patrol.component.html',
  styleUrls: ['./group-patrol.component.scss'],
})
export class GroupPatrolComponent implements OnInit, OnChanges, OnDestroy {
  @ViewChild('bottomOfJobCreation', { static: false })
  bottomOfJobCreationRef: ElementRef;

  @Input() layoutId: string;
  @Input() events: Observable<void>;
  @Input() skillId: string;
  @Input() robots?: Robot[] = [];
  @Input() robotSkills: Skill[] = [];
  @Output() closeDrawer = new EventEmitter();
  @Output() resetType = new EventEmitter();

  selectZones: SelectOption[] = [];
  selectedZoneList: string[] = [];
  isShowAddButton: boolean = true;
  canSelectZone: boolean = false;
  totalRepeat: number = 0;

  schedule: string;
  selectedStartJob: number;

  isBlinkingLightsCheck: boolean;
  blinkingLightsSkill: Skill;
  taskBlinkingLights;
  taskBlinkingLightsOff;
  isBroadcastMessageCheck: boolean;
  broadcastMessageSkill: Skill;
  taskBroadcastMessage;
  taskBroadcastMessageOff;
  ttsMessageSkill: Skill;
  taskTtsMessage;

  private _unsubscribeAll: Subject<any> = new Subject<any>();

  constructor(
    private apiLayoutMap: ApiLayoutMap,
    private dashboardSrvc: DashboardService,
    private snackBar: SnackBarService,
    private newJobSrvc: NewJobService,
    private _datePipe: DatePipe,
    private apiRegion: ApiRegion,
    private apiCampaign: ApiCampaign,
    public dialog: MatDialog,
    private prettifyPrintPipe: PrettifyPrintPipe
  ) {}

  ngOnInit(): void {
    // default value for group patrol is 4 (start and abort current job) as per PM request
    this.selectedStartJob = 4;

    // if selectedLayoutId is changed
    this.apiLayoutMap.selectedLayoutId$
      .pipe(takeUntil(this._unsubscribeAll))
      .subscribe((layoutId) => {
        this.layoutId = layoutId;
        // only show one dropdown at a time, due to this bug
        // on mat-select:
        // https://github.com/angular/components/issues/19510
        this.selectedZoneList = [''];
        this.selectZones = [];
        this.isShowAddButton = true;
        this.canSelectZone = false;
        this.getZones().then((zones) => {
          zones
            .sort((a, b) => a.name.localeCompare(b.name))
            .map((zone) => {
              const jsonData = JSON.stringify({
                markerId: zone.id,
                name: zone.name,
              });
              this.selectZones.push({
                display: zone.name,
                value: jsonData,
              });
            });
        });
      });

    this.events.pipe(takeUntil(this._unsubscribeAll)).subscribe(() => {
      this.submitForm();
    });

    // check if the robot have below skills
    this.blinkingLightsSkill = this.robotSkills.find(
      (skill) => skill.name === 'RM-BLINKER-PARALLEL'
    );

    this.broadcastMessageSkill = this.robotSkills.find(
      (skill) => skill.name === 'RM-BROADCAST-PARALLEL'
    );

    this.ttsMessageSkill = this.robotSkills.find(
      (skill) => skill.name === 'RM-TTS-PARALLEL'
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.robotSkills) {
      if (this.robotSkills) {
        // check if the robot have below skills
        this.blinkingLightsSkill = this.robotSkills.find(
          (skill) => skill.name === 'RM-BLINKER-PARALLEL'
        );

        this.broadcastMessageSkill = this.robotSkills.find(
          (skill) => skill.name === 'RM-BROADCAST-PARALLEL'
        );

        this.ttsMessageSkill = this.robotSkills.find(
          (skill) => skill.name === 'RM-TTS-PARALLEL'
        );
      }
    }
  }

  selectStartJobType($event): void {
    this.selectedStartJob = $event;
  }
  jobSchedule($event): void {
    this.schedule = $event;
  }

  /**
   * Helper function to get zone list form API
   * @returns
   */
  private getZones(): Promise<Region[]> {
    return new Promise((resolve) => {
      this.apiRegion.listZoneAreas(this.layoutId).subscribe((data) => {
        if (data.code === 200) {
          resolve(data.result || []);
        } else {
          resolve([]);
        }
      });
    });
  }

  /**
   * Helper function to add new zone dropdown
   */
  public addZoneDropdown(): void {
    if (this.disableAddZonePatrol) {
      return;
    }

    this.isShowAddButton = false;
    this.canSelectZone = true;
    this.selectedZoneList.push('');
  }

  /**
   * Helper function to remove zone dropdown on specific index
   *
   * @param index
   */
  public removeZoneDropdown(index: number): void {
    this.selectedZoneList.splice(index, 1);
    this.updateButtonStatus();
  }

  /**
   * Helper function to assign selected zone from click map or dropdown
   *
   * @param event Value of selected destination
   * @param action type of action which destination is clicked.
   * If 'zone' its mean destination clicked in the zone on map.
   * If 'option' its mean destination selected in the option dropdown
   *
   */
  public handleChangeZone(
    event: SelectOption,
    action: 'zone' | 'option',
    index?: number
  ): void {
    const value = event.value;
    const selectedZoneExist = this.selectedZoneList.find(
      (zone) => zone === value
    );

    // Check if zone is selected twice
    if (selectedZoneExist) {
      this.snackBar.openSnackBar({
        message: 'Cannot select same zone twice',
        type: 'failed',
      });
      return;
    }

    if (action === 'zone') {
      if (this.selectedZoneList.includes('')) {
        const emptyZoneIndex = this.selectedZoneList.findIndex(
          (zone) => zone === ''
        );
        this.selectedZoneList[emptyZoneIndex] = value;
      } else {
        this.selectedZoneList.push(value);
      }
    } else if (action === 'option') {
      if (this.selectedZoneList.includes('')) {
        const emptyZoneIndex = this.selectedZoneList.findIndex(
          (zone) => zone === ''
        );
        this.selectedZoneList[emptyZoneIndex] = value;
      } else {
        this.selectedZoneList[index] = value;
      }
    }

    this.updateButtonStatus();
  }

  /**
   * Helper function to update total repeat of the job
   *
   * @param numberRepeat Number of repeat
   */
  public handleRepeatChange(numberRepeat: number): void {
    this.totalRepeat = numberRepeat;
  }

  /**
   * Helper function to update status of map, button, and drag able list
   */
  private updateButtonStatus(): void {
    // If the last dropdown is empty and there is a dropdown, hide add destination button and make the map clickable
    if (
      this.selectedZoneList.includes('') &&
      this.selectedZoneList.length > 0
    ) {
      this.isShowAddButton = false;
      this.canSelectZone = true;
    } else {
      this.isShowAddButton = true;
      this.canSelectZone = false;
    }
  }

  /**
   * Helper function to create patrol area job
   */
  private submitForm(): void {
    if (this.robots.length === 0) {
      this.snackBar.openSnackBar({
        message: 'Please select at least 1 robot',
        type: 'failed',
      });
      return;
    }

    // Check if there is no destination selected
    if (this.selectedZoneList.length === 0) {
      this.snackBar.openSnackBar({
        message: 'Please select at least one zone',
        type: 'failed',
      });
      return;
    }

    // Check if all destination marker has been selected
    if (this.selectedZoneList.includes('')) {
      this.snackBar.openSnackBar({
        message: 'Please fill all the zone dropdown',
        type: 'failed',
      });
      return;
    }

    // Check if the number of robots is greater then the number of selected zones.
    if (this.robots.length > this.selectedZoneList.length) {
      this.snackBar.openSnackBar({
        message:
          'Number of selected robots is greater than the number of selected zones',
        type: 'failed',
      });
      return;
    }

    // Check if malfuntion or low battery robot in slected list.
    const invalidRobots = this.robots
      .filter(
        (robot) =>
          robot.state === 5 || (robot.state !== 4 && +robot.battery < 20)
      )
      .map((robot) => robot.name);
    if (invalidRobots.length > 0) {
      const formattedName = this.prettifyPrintPipe.transform(
        invalidRobots.join(',')
      );
      this.snackBar.openSnackBar({
        message: `${formattedName} cannot do the group patrol because of malfunctioning or low on battery. Please remove from the list or select other robot.`,
        type: 'failed',
      });
      return;
    }

    // Mapping each routing list into task params object format
    const descriptionZone: string[] = [];

    //Add all concarent tasks
    let allTask = [];

    // first add the blinking lights on, if toggled
    if (this.isBlinkingLightsCheck) {
      allTask.push(this.taskBlinkingLights);
    }

    // add broadcast on
    if (this.isBroadcastMessageCheck) {
      allTask = [...allTask, ...this.taskBroadcastMessage];
    }

    if (this.isBroadcastMessageCheck) {
      // allTask.push(this.taskBroadcastMessage);
      if (this.taskBroadcastMessage[0].params[2].paramKey !== 'tts') {
        this.taskBroadcastMessageOff = {
          order: 0,
          skillId: this.taskBroadcastMessage[0].skillId,
          params: [
            {
              paramKey: 'switch',
              paramValue: 2,
            },
            this.taskBroadcastMessage[0].params[1], // params repeat
            this.taskBroadcastMessage[0].params[2], // params url
            this.taskBroadcastMessage[0].params[3], // params type
          ],
        };
        allTask = [...allTask, this.taskBroadcastMessageOff];
      } else {
        this.taskBroadcastMessageOff = {
          order: 0,
          skillId: this.taskBroadcastMessage[0].skillId,
          params: [
            {
              paramKey: 'switch',
              paramValue: 2,
            },
            this.taskBroadcastMessage[0].params[1], // params repeat
            this.taskBroadcastMessage[0].params[2], // params tts message
          ],
        };
        allTask = [...allTask, this.taskBroadcastMessageOff];
      }
    }

    // last, add the blinking lights off, if toggled
    if (this.isBlinkingLightsCheck) {
      allTask.push(this.taskBlinkingLightsOff);
    }

    const robotIds = this.robots.map((robot) => robot.id);

    // add zone list
    const zoneList = this.selectedZoneList.map((zone) => {
      const paramObj = JSON.parse(zone);
      descriptionZone.push(paramObj.name);

      return paramObj.markerId;
    });

    // Create payload to create group patrol job based on payload for
    // create mission in https://docs.robotmanager.com/reference/create-a-missionsupport-quick-mission-copy
    const payload = {
      followPath: 1, // 1 = yes; 2 = no;
      mode: this.selectedStartJob,
      name:
        'GROUP PATROLS ' +
        this._datePipe.transform(new Date(), 'dd/MM/yyyy HH:mm:ss'),
      priority: 1,
      repeat: this.totalRepeat,
      type: 2, // 1 = job; 2 = mission;
      tasks: [...allTask],
      layoutId: this.layoutId,
      robotList: [...robotIds],
      regionList: [...zoneList],
      scheduledAt: this.selectedStartJob === 3 ? this.schedule : null,
    };

    // As per PM request, the group patrol the user will abort the current active job,
    // the default mode is 4 (start and abort current job) for group patrol,
    // no need for user to choose the job mode
    // check if job mode is `Start now and abort current job`
    // if true, abort all current running job
    // then, create a new patrol job
    // mode `Start now and abort current job` === 5
    if (this.selectedStartJob === 4) {
      const abortAllRobotsJob: boolean[] = [];
      robotIds.map(async (robotId) => {
        const isAborted = await this.dashboardSrvc.abortAllCurrentJob(robotId);
        abortAllRobotsJob.push(isAborted);
      });

      if (abortAllRobotsJob.every((result) => result)) {
        // All mission paused successfully, create a new patrol job
        // based on Kae Yan, the value of the Mission should be like this:
        // mode: 1 = Start now and pause current job
        // scheduleType/status: 4 = start
        payload.mode = 1;
        payload['status'] = 4;
        this.createPatrolJob(payload);
      } else {
        // there are some mission that failed to pause / no mission to pause
        console.log(
          'allPaused',
          abortAllRobotsJob.every((result) => result)
        );
      }
    } else {
      this.createPatrolJob(payload);
    }
  }

  private createPatrolJob(payload) {
    //assign isSubmited = true to show loading in button
    this.newJobSrvc.isSubmited$.next(true);

    this.apiCampaign.create(payload).subscribe((res) => {
      const robotsLengthStr = this.robots.length > 1 ? 'robots' : 'robot';

      if (res.code === 200) {
        this.snackBar.openSnackBar({
          message: `New job added to ${this.robots.length} ${robotsLengthStr} successfully.`,
          type: 'success',
        });
        // reset all field
        this.selectedZoneList = [];
        this.closeDrawer.emit();
        this.resetType.emit();
      } else {
        this.snackBar.openSnackBar({
          message: `Failed add new job to ${this.robots.length} ${robotsLengthStr}`,
          type: 'failed',
        });
      }

      //assign isSubmited = false to hide loading in button
      this.newJobSrvc.isSubmited$.next(false);

      //assign isNewJobCreated$ = true, so in the job list there will be a progressbar if the new job created
      this.newJobSrvc.isNewJobCreated$.next(true);
    });
  }

  handleBlinkingLightsCheck(event) {
    this.isBlinkingLightsCheck = event.checked;
    if (this.isBlinkingLightsCheck) {
      //   const paramObj = JSON.parse(this.selectedMarker);
      // switch on the blinking lights
      const taskParam = {
        paramKey: 'switch',
        paramValue: 1,
      };
      this.taskBlinkingLights = {
        order: 0,
        mandatory: 1,
        skillId: this.blinkingLightsSkill.id,
        params: [taskParam],
      };

      // switch off the blinking lights
      const taskParamOff = {
        paramKey: 'switch',
        paramValue: 2,
      };
      this.taskBlinkingLightsOff = {
        order: 0,
        mandatory: 1,
        skillId: this.blinkingLightsSkill.id,
        params: [taskParamOff],
      };
    } else {
      this.taskBlinkingLights = {};
    }
  }

  handleBroadcastMessageCheck(event) {
    this.isBroadcastMessageCheck = event.checked;

    setTimeout(() => {
      this.bottomOfJobCreationRef.nativeElement.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest',
      });
    }, 200);
  }

  taskDataBroadcastMessage(event) {
    this.taskBroadcastMessage = event;
  }

  ngOnDestroy(): void {
    // Unsubscribe from all subscriptions
    this._unsubscribeAll.next(null);
    this._unsubscribeAll.complete();
  }

  // disable the add zone patrol button if there is a
  // empty string in the zone list
  public get disableAddZonePatrol(): boolean {
    return this.selectedZoneList.includes('');
  }

  public getZoneName(zoneList: string) {
    const json = JSON.parse(zoneList);
    const found = this.selectZones.find((selectZone) => {
      const zone = JSON.parse(selectZone.value);
      if (get(zone, 'name') !== get(json, 'name')) return false;
      if (get(zone, 'markerId') !== get(json, 'markerId')) return false;
      return true;
    });
    return found ? found.display : '';
  }
}
